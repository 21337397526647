import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import useScript from "../service/useScript"
import Layout from "../components/layout/layout"
import Meta from "../components/addon/meta"

import BlogCard from "../components/addon/blogCard"
import VideoModal from "../components/addon/videoModal"


const AllCategoryBlogsListing = props => {
    const { data, pageContext } = props
    useScript("/assets/js/bootstrap4-rating-input.js")
    useScript("/assets/js/star_rating.js")
    useScript("/assets/js/blog-slider.js")

    const [videoURL, setVideoURL] = useState("")
    const [showModal, setShowModal] = useState(false)
    let [metaTags] = useState([])
    let [showNoDataText, setShowNoDataText] = useState(false)
    let [blogList, setBlogList] = useState([])
    useEffect(() => {
        if (data && data?.allNodeArticle?.nodes && data.allNodeArticle.nodes.length > 0){
            let arr = data.allNodeArticle.nodes.map(el => {
                return {
                    title: el.title,
                    path: el?.path?.alias,
                    headerImage:
                    el?.relationships?.image?.uri?.url ||
                    null,
                    category: pageContext.categoryType,
                    summary: el?.summary?.summary,
                    date: el?.created,
                    video: el?.field_video?.input || null,
                    author: el.author,
                    type: el.blogType,
                }
            })
            const sortByDate = arr => {
                const sorter = (a, b) => {
                    let aDate = (a.date).split(',').join(' ').split('|').join('')
                    let bDate = (b.date).split(',').join(' ').split('|').join('')
                   return new Date(bDate).getTime() - new Date(aDate).getTime();
                }
                arr.sort(sorter);
            };
            sortByDate(arr);
            setBlogList(arr)
        } else {
            setShowNoDataText(true)
        }
    }, []);
    const openVideoModal = url => {
        setVideoURL(url)
        setShowModal(true)
    }
    const closeModal = () => {
        setShowModal(false)
    }
    return (
        <Layout>
            <Meta
                files={{
                js: [],
                css: ["/assets/css/blog.css"],
                }}
                tags={metaTags}
            />
            <main>
                <section className="breadcrumbs">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <ul>
                                    <li><a href="/">Home</a></li>
                                    <li><span>Blogs</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section-bg section-py">
                    <div className="container">
                        <div className="row filter_row">
                            <div className="col-md-12">
                                <h2 className="section-heading">Blogs</h2>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            {blogList &&
                            blogList.length > 0 &&
                            blogList.map((el, index) => {
                            return (
                            <div className="col-lg-4 col-md-6 mb-4" key={index}>
                                <BlogCard data={el} openVideoModal={openVideoModal} />
                            </div>
                            )
                            })}
                        </div>
                        {showNoDataText === true && (
                        <div className="load_more_wrap text-center">
                            <p>Currently there is no data</p>
                        </div>
                        )}
                    </div>
                </section>
            </main>
            <VideoModal
                videoURL={videoURL}
                showModal={showModal}
                closeModal={closeModal}
                />
        </Layout>
    )
}

export const query = graphql`
query($blogType: String!) {
allNodeArticle(filter: { field_article_type: { eq: $blogType } }) {
nodes {
id
title
path {
alias
}
blogType: field_article_type
created(formatString: "MMMM D,YYYY | hh:mm A")
author: field_author
title
field_image {
alt
}
summary: body {
summary
}
relationships {
image: field_image {
id
uri {
    value
    url
}
}

}
}
}
}
`
export default AllCategoryBlogsListing